<script lang="ts">
	import Pill from "@components/Pill.svelte";
	import Formatted from "@components/common/Formatted.svelte";
	import Icon from "@components/common/Icon.svelte";
	import type { InfoCardProps } from "./InfoCardProps.js";
	import { PillColor, PillSize } from "./PillProps.js";

	const { title, iconName, items, indexNumber, desc }: InfoCardProps = $props();
	const isTitleOnly = !items && !desc;
</script>

<div class="bg-white p-4 lg:p-10 flex flex-col gap-2 md:gap-4 lg:gap-7">
	<div class={{ "flex flex-col items-start gap-4 max-w-72": true, "max-w-full": isTitleOnly }}>
		{#if iconName}
			<Icon icon={iconName} class="text-fstgreen"></Icon>
		{:else if indexNumber}
			<span class="text-fstgreen font-loos-wide text-lg">{indexNumber}</span>
		{/if}
		{#if title}
			<h3
				class={[
					isTitleOnly
						? "text-md font-loos-wide text-fstonyx font-normal lg:text-lg"
						: "text-md lg:text-lg font-bold text-fstonyx -tracking-[0.02em]",
				]}
			>
				<Formatted text={title} />
			</h3>
		{/if}
	</div>
	<div class="flex flex-wrap gap-1 lg:gap-2">
		{#each items ?? [] as item (item)}
			<Pill size={PillSize.Medium} color={PillColor.Light}><Formatted text={item} /></Pill>
		{/each}

		{#if desc}
			<p class="text-fstgrey-200"><Formatted text={desc} /></p>
		{/if}
	</div>
</div>
